export const productTemplate = `
<div class="col-12 col-sm-6 col-md-3 product-sortable product" data-sku="{sku}" data-s1="1" data-s2="{title}" data-s3="{price}">
<a class="transparent-link" href="/{url}">
    <div class="product-listing">
        <picture class="product-picture-listing">
            <source media="(min-width: 769px)" srcset="/images/{sku}/listing-desktop-x1-webp/{sku}.webp 1x, /images/{sku}/listing-desktop-x2-webp/{sku}.webp 2x" type="image/webp">
            <source media="(min-width: 769px)" srcset="/images/{sku}/listing-desktop-x1-jpg/{sku}.jpg 1x, /images/{sku}/listing-desktop-x1-jpg/{sku}.jpg 2x" type="image/jpeg">
            <source media="(max-width: 768px)" srcset="/images/{sku}/listing-mobile-x1-webp/{sku}.webp" type="image/webp">
            <source media="(max-width: 768px)" srcset="/images/{sku}/listing-mobile-x1-jpg/{sku}.jpg" type="image/jpeg">
            <img src="/images/{sku}/listing-mobile-x1-jpg/{sku}.jpg" alt="{title}" loading="lazy">
        </picture>
    </div>
    <span class="h3 search">{title}{subtitle}</span>
    <p>{price-grid}</p>
    <div class="three-line search">{webShortDescription}</div>
</a>
</div>
`

export const productsGlue = ``

export const productsResults = `
<section class="categories-grid default-section">
    <div class="row">
        {product_results}
    </div>
</section>
`

export const emptySearch = `
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
`

export const noResultsTemplate = `
No matching results for found. Try another search.
<div class="hr"><hr></div>
` + productsResults

import {searchUI} from "./search";
import {logFactory} from "./log";
import {productsResults} from "./templates";
const log = logFactory('search:ui:url-handler')
$(function(){
    const query: {[id:string]: string} = {}
    if (location.search) {
        const kvps = location.search.substr(1).split('&')
        for(const kvp of kvps) {
            const [key, value]: string[] = kvp.split('=')
            query[decodeURIComponent(key)] = decodeURIComponent(value)
        }
    }

    if ('q' in query) {
        log('inbound query is set', query['q'])
        $('#q').val(query['q'])
        searchUI.search(query['q'])
    } else {
        const url = new URL(location.href.toLowerCase())
        if (url.pathname.startsWith("/search")){
            $('#q')[0].focus();
            $('#main').html('<br><br><br><br><br><br><br><br><br><br>')
        }
    }

    window.onpopstate = function(event:PopStateEvent) {
        log("onpopstate", document.location, event.state);
        if (event.state != null && 'q' in event.state) {
            searchUI.search(event.state.q)
            $('#q').val(event.state.q)
        } else {
            location.reload()
        }

    };
    const historyDict: {[id:string]: boolean} = {}
    searchUI.addEventListener('newsearch', function(event:CustomEvent){
        const term = event.detail
        log('newsearch event, setting history', term)
        if (query['q'] === term) {
            log('newsearch event, not pushing history for landing page (already exists)', term)
            return
        }
        if (term in historyDict) {
            log('newsearch event, not pushing history navigation is historic', term)
            return
        }
        historyDict[term] = true
        log('newsearch event, setting history - pushing state', term)
        history.pushState({q: term},
            `Search: ${term} | Phaidon`,
            `/search/?q=${encodeURIComponent(term)}`)
    })
})

import {
    emptySearch,
    productTemplate,
    productsGlue,
    noResultsTemplate,
    productsResults
} from "./templates";
import {ArticleSearchResult, ProductSearchResult, SearchResult} from "./interfaces";
import {autocompleteDatasource, searchUI} from "./search";
import {logFactory} from "./log";
const log = logFactory('search:ui')
async function renderProduct(r: ProductSearchResult) {
    const products = await (window as any).phaidon.products$
    const p = Object.assign({}, r.product) as any
    const imagePath = `${p.sku}/${p.sku}`
    p.image = imagePath
    p.data = `data-sku="${p.sku}" data-url="${p.url}" data-title="${p.title}" data-image="/images/listing-desktop-x1/${imagePath}"`
        + `data-language="${p.language}" data-binding="${p.binding}"`
    return productTemplate.replace(/{([^}]+)}/g, (org_match:string, name:string):string => {
        if (name === 'subtitle') {
            if (p[name]){
                return ': ' + p[name]
            }
            return ''
        } else if (name === 'price-grid') {
            const editionDescription = p.editionDescription ? ` | ${p.editionDescription}` : ''
            const price = p.sku in products ? products[p.sku].price : null;
            return `
            <span class="price aud"><span class="sr-only">Price </span>AUD$${price}${editionDescription}</span>
            <span class="price cad"><span class="sr-only">Price </span>CAD$${price}${editionDescription}</span>
            <span class="price eur"><span class="sr-only">Price </span>€${price}${editionDescription}</span>
            <span class="price gbp"><span class="sr-only">Price </span>£${price}${editionDescription}</span>
            <span class="price usd"><span class="sr-only">Price </span>USD$${price}${editionDescription}</span>`
        }
        return p[name]
    })
}
function getSortProp(item: SearchResult): number{
    if (item.type == 'product')
        return (item as ProductSearchResult).product.order
    if (item.type == 'article')
        return (item as ArticleSearchResult).article.order
}
function sort(a: SearchResult,b: SearchResult){
    const bOrder = getSortProp(a) // desc
    const aOrder = getSortProp(b)
    if (aOrder < bOrder)
        return -1
    if (aOrder > bOrder)
        return 1
    return 0
}
function renderResults(event: {detail: SearchResult[]}|any, noResults = false) {
    log('renderResults', event.detail.length)

    const results = event.detail
    const productHtml$ = []
    let maxProducts = 50
    for (const r of results) {
        if (r.type == 'product') {
            maxProducts--
            if (maxProducts > 0) {
                productHtml$.push(renderProduct(<ProductSearchResult>r))
            }
        }
    }
    Promise.all(productHtml$).then(function(productHtml){
        if (noResults) {
            const template = event.term == '' ? emptySearch : noResultsTemplate
            $('#main').html(template.replace('{product_results}', productHtml.join(productsGlue)))
        } else {
            $('#main').html(productsResults
                .replace('{product_results}', productHtml.join(productsGlue))
            )
        }
        if (typeof (window as any).phaidon !== 'undefined') {
            (window as any).phaidon.updateUi()
        }
    })

}
searchUI.addEventListener('search', renderResults)
searchUI.addEventListener('noresults', (event:CustomEvent) => {
    log('noresults', event.detail.length)
    renderResults(event, true)
})
searchUI.addEventListener('product-found', (event:CustomEvent) => {
    log('product-found', event.detail.url)
    location.replace(`/${event.detail.url}`)
})
$(function() {
    let searchInProgress = true
    const $search = <any>$('#q')
    $search.typeahead({
        minLength: 2,
        highlight: true
    }, {
        name: 'products',
        async: true,
        source: autocompleteDatasource.source
    }).on('typeahead:change', function(){
        log('typeahead:change')
        if (!searchInProgress)
            search($search.val() as string)
        $search.typeahead('close')
    }).on('typeahead:autocomplete', function(){
        log('typeahead:autocomplete')
        if (!searchInProgress)
            search($search.val() as string)
    }).on('keydown', function(event: KeyboardEvent) {
        if(event.key == "Enter") {
            event.preventDefault()
            log('typeahead:enter')
            searchInProgress = true
            const val = ($(this).prev().val() || $search.typeahead('val')) as string
            log(`typeahead: search ${val}`)
            search(val)
            $search.val(val)
            $search.typeahead('close')
            setTimeout(function(){searchInProgress = false}, 300)
        }
    });
    $('input.tt-hint').attr('aria-hidden', "true")
    function search(term: string){
        searchUI.search(term)
    }
})

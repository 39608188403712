import SearchWorker from "worker-loader!./worker/worker";
import {SearchResult} from "./interfaces";
import {logFactory} from "./log";
import 'style-loader!./search.css';
const worker = new SearchWorker();


class EventDispatcher { // shim / polyfill, (due to safari) replace EventTarget
    private _listeners: any[];

    constructor() {
        this._listeners = [];
    }

    hasEventListener(type: any, listener: any) {
        return this._listeners.some(item => item.type === type && item.listener === listener);
    }

    addEventListener(type: any, listener: any) {
        if (!this.hasEventListener(type, listener)) {
            this._listeners.push({type, listener, options: {once: false}});
        }
        return this
    }

    removeEventListener(type: any, listener: any) {
        let index = this._listeners.findIndex(item => item.type === type && item.listener === listener);
        if (index >= 0) this._listeners.splice(index, 1);
        return this;
    }

    removeEventListeners() {
        this._listeners = [];
        return this;
    }

    dispatchEvent(evt: any) {
        this._listeners
          .filter(item => item.type === evt.type)
          .forEach(item => {
              const {type, listener, options: {once}} = item;
              listener.call(this, evt);
              if (once === true) this.removeEventListener(type, listener)
          });
        return this
    }
}

export class SearchUI extends EventDispatcher {
    private log = logFactory("search:ui:SearchUI")
    renderDefaultResults() {
        this.log('renderDefaultResults')
        worker.postMessage({type: 'default-results', term: ''})
    }
    autocomplete (term: string) {
        this.log('autocomplete', term)
        worker.postMessage({type: 'auto', term: term})
    }
    search (term: string) {
        this.log('search', term)
        worker.postMessage({type: 'search', term: term})
    }
    onmessage = (event:MessageEvent) => {
        if (event.data.type == 'auto') {
            this.dispatchEvent(new CustomEvent("suggestion", {detail: JSON.parse(event.data.results)}))
        } else if (event.data.type == 'search') {
            this.dispatchEvent(new CustomEvent("search", {detail: JSON.parse(event.data.results)}))
        } else if (event.data.type == 'search-started') {
            this.dispatchEvent(new CustomEvent("newsearch", {detail: event.data.term}))
        } else if (event.data.type == 'product-found') {
            this.dispatchEvent(new CustomEvent("product-found", {detail: {url: event.data.url}}))
        } else if (event.data.type == 'noresults') {
            this.dispatchEvent(new CustomEvent("noresults", {
                detail: JSON.parse(event.data.results)
            }))
        }
    }
    constructor() {
        super()
        this.log('constructor')
        worker.onmessage = this.onmessage
    }
}
export const searchUI = new SearchUI()


export class AutocompleteDatasource {
    private log = logFactory("search:ui:AutocompleteDatasource")
    private _asyncResponse: (results: SearchResult[]) => void|null = null
    constructor(private searchUI: SearchUI) {
        this.log('constructor')
        searchUI.addEventListener('suggestion', this.results as any)
    }
    source = (term: string, syncResponse: () => void, asyncResponse: () => void) => {
        this.log('source', term)
        this._asyncResponse = asyncResponse
        this.searchUI.autocomplete(term)
    }
    results = (event: {detail: SearchResult[]}) => {
        this.log('results', event)
        if (this._asyncResponse) {
            this._asyncResponse(event.detail)
        }
    }
}
export const autocompleteDatasource = new AutocompleteDatasource(searchUI)

if (window) {
    (window as any).searchUI = searchUI
}
